import React from 'react';
import {Route, Routes} from "react-router-dom";
import MainWrapper from "../pages/MainWrapper";
import CaptureImage from "../pages/CaptureImage";

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<MainWrapper/>}>
                <Route index element={<CaptureImage/>}/>
            </Route>
        </Routes>
    );
};

export default Router;