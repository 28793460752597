import React, { useEffect, useRef } from 'react';

import classes from './styles.module.css'
import Button from "../../components/Button";

// @ts-ignore
import logo from "../../assets/logo.JPG";

import DraggableImage from "../../components/DraggableImage";
import { url } from 'inspector';

import mainLogoSVG from '../../assets/images/logo.svg';
import mainLogo from '../../assets/images/logo.png';
import desktopBG from '../../assets/images/desktop-bg.jpg';
import screenBG from '../../assets/images/screen-bg.jpg';
import screenBG1 from '../../assets/images/screen1-bg.jpg';
import gallery1 from '../../assets/images/gallery1.png';
import gallery2 from '../../assets/images/gallery2.png';
import gallery3 from '../../assets/images/gallery3.png';
import gallery4 from '../../assets/images/gallery4.png';
import gallery5 from '../../assets/images/gallery5.png';
import gallery6 from '../../assets/images/gallery6.png';
import cameraIconSVG from '../../assets/images/camera-icon.svg';
import markLogo from "../../assets/images/icici-mark.png";
// import model from "../../assets/images/model.png";
import model1 from "../../assets/images/model1.png";
import model2 from "../../assets/images/model2.png";
import model3 from "../../assets/images/model3.png";
import model4 from "../../assets/images/model4.png";
import model5 from "../../assets/images/model5.png";
import model6 from "../../assets/images/model6.png";
import html2canvas from "html2canvas";

const CaptureImage = () => {
    const canvasRef = useRef<null | HTMLCanvasElement>(null);
    const borderRef = useRef<null | HTMLDivElement>(null);

    const [screenType, setScreenType] = React.useState<number>(1);
    const [selectedModel, setSelectedModel] = React.useState<any>(null);
    const [previewImage, setPreviewImage] = React.useState<any>(null);
    const [facingMode, setfacingMode] = React.useState<any>('user');

    const [modelList, setModelList] = React.useState([
        { id: model1, value: gallery1, name: 'model1' },
        { id: model2, value: gallery2, name: 'model2' },
        { id: model3, value: gallery3, name: 'model3' },
        { id: model4, value: gallery4, name: 'model4' },
        { id: model5, value: gallery5, name: 'model5' },
        { id: model6, value: gallery6, name: 'model6' },
    ]
    );


    const onChangeScreen = (screen: number) => {
        setScreenType(screen)
    }
    const onSelectModel = (obj: any) => {
        setSelectedModel(obj)
    }
    useEffect(() => {
        setSelectedModel(modelList[0]);

    }, [])

    const cameraFlip = () => {
        // console.log(facingMode);
        if(facingMode == 'user'){
            setfacingMode('environment');
        }else{
            setfacingMode('user');
        }
    }

    
    useEffect(() => {
        // console.log(facingMode);  
        navigator.mediaDevices.getUserMedia({
            // video: { width: 320, height: 500 }
            video: {
                facingMode: facingMode
            }
        }).then((stream) => {
            // Get a reference to the video element
            const video = document.getElementById('video') as HTMLVideoElement;

            if (!video) return;
            if (!canvasRef.current) return;

            // Set the srcObject of the video element to the MediaStream object
            video.srcObject = stream;
            video.onloadedmetadata = () => {
                video.play();
            };

            // Get the canvas context
            const ctx = canvasRef.current.getContext('2d');

            // Draw the video onto the canvas in a loop using requestAnimationFrame
            function draw() {
                if (!canvasRef.current) return;
                ctx?.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current?.height);
                requestAnimationFrame(draw);


                const footer = new Image();
                footer.src = markLogo

                ctx?.drawImage(footer, 0, 420, canvasRef.current.width, 80);
            }

            draw();
        }).catch((e) => {
            alert(e.message)
        });
    }, [screenType === 3,facingMode]);


    const download = function (href: string) {
        const link = document.createElement('a');
        link.download = 'ICICI.png';
        link.href = href
        link.click();
        onChangeScreen(6)

    }

    const handleCapture = () => {
        if (!borderRef.current) return;

        html2canvas(borderRef.current).then(canvas => {
            canvas.toBlob((blob) => {
                if (blob) {
                    const url = window.URL.createObjectURL(blob);
                    // download(url)
                    setPreviewImage(url)
                    onChangeScreen(4)
                }
            }, 'image/png', 1)
        })



    }

    return (
        <div className=''>
          
            {/* <div className={classes.wrapper}>

                <div ref={borderRef} className={classes.canvasBorder}>
                    <video id="video" />

                    <DraggableImage id="man-image" src={man} ref={borderRef} />

                    <img
                        src={logo}
                        style={{ position: 'absolute', bottom: 0, zIndex: 11 }}
                        width="100%"
                        height={80}
                    />

                    <canvas ref={canvasRef} width={320} height={500} />
                </div>

                <Button onClick={handleCapture}>
                    Capture
                </Button>
            </div> */}

            <section className="icici-wrapper" id="icici-mobile">

                {(() => {
                    switch (screenType) {
                        case 1:
                            return (<div id="screen1" className="icici-screen icici-screen1" style={{ backgroundImage: `url(${screenBG1})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            <button className="btn btn-primary btn-padding btn-rounded" id="btnstep1" onClick={() => { onChangeScreen(2) }}>Start</button>
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                            break;
                        case 2:
                            return (<div id="screen2" className="icici-screen icici-screen2" style={{ backgroundImage: `url(${screenBG})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            <div className="icici-gallery">
                                                <ul>
                                                    {modelList.map((val, index) => {
                                                        return (

                                                            <li key={index} className={selectedModel && selectedModel.id === val.id ? 'active' : ''} onClick={() => { onSelectModel(val) }}><img src={val.value} alt="ICICI Prudential" /></li>
                                                        )
                                                    })}
                                                    {/* <li><img src={gallery2} alt="ICICI Prudential" /></li>
                                                    <li><img src={gallery3} alt="ICICI Prudential" /></li>
                                                    <li><img src={gallery4} alt="ICICI Prudential" /></li>
                                                    <li><img src={gallery5} alt="ICICI Prudential" /></li>
                                                    <li><img src={gallery6} alt="ICICI Prudential" /></li> */}
                                                </ul>
                                            </div>
                                            <button className="btn btn-primary btn-padding btn-rounded" id="btnstep2" onClick={() => { onChangeScreen(3) }}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                        case 3:
                            return (<div id="screen3" className="icici-screen icici-screen3" style={{ backgroundImage: `url(${screenBG})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            <div ref={borderRef} className={classes.canvasBorder}>
                                                <video id="video"  autoPlay playsInline/>
                                                {
                                                    selectedModel && (
                                                        <DraggableImage id="man-image" src={selectedModel.id} ref={borderRef} />
                                                    )}

                                                <img src={markLogo} className={classes.marklogo} />
                                                

                                                <canvas ref={canvasRef}  />
                                                {/* <canvas ref={canvasRef} id="responsive-canvas"  /> */}
                                                {/* <canvas ref={canvasRef} width={320} height={500} /> */}
                                            </div>

                                            <div className='frontback-option' >
                                                <div className=''>
                                                    <div className="switch-toggle">
                                                        <input type="checkbox" id="switch" name="switch" onChange={() => {cameraFlip()}}/>
                                                        <label className="toggle-label" htmlFor="switch">Toggle</label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary btn-padding btn-rounded" onClick={handleCapture}>
                                                    <img src={cameraIconSVG} alt="ICICI Prudential" />
                                                </button>
                                            </div>


                                            {/* <div className="icici-frame" style="background-image: url('assets/images/frame.png');">
        
                                </div> */}
                                            {/* <button className="btn btn-primary btn-padding btn-rounded" id="btnstep3">
                                            <img src="assets/images/camera-icon.svg" alt="ICICI Prudential" />
                                        </button> */}
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                        case 4:
                            return (<div id="screen4" className="icici-screen icici-screen4" style={{ backgroundImage: `url(${screenBG})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            {/* <div className="icici-frame" style="background-image: url('assets/images/frame.png');">
        
                                </div> */}
                                            <div className='preview-box'>
                                                <div className='preview-image'>
                                                    {previewImage && <img src={previewImage} />}
                                                </div>
                                                <div className='preview-frame'>
                                                    <img src={markLogo} className={classes.marklogo} />
                                                </div>
                                            </div>

                                            <div className="button-group">
                                                <button className="btn btn-danger btn-padding btn-rounded me-1" id="btnstepprev3" onClick={() => { onChangeScreen(3) }}>Retake</button>
                                                <button className="btn btn-success btn-padding btn-rounded ms-1" id="btnstep4" onClick={() => { onChangeScreen(5) }}>Good</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                        case 5:
                            return (<div id="screen5" className="icici-screen icici-screen5" style={{ backgroundImage: `url(${screenBG})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            {/* <div className="icici-frame" style="background-image: url('assets/images/frame.png');">

                        </div> */}
                                            <div className='preview-box'>
                                                <div className='preview-image'>
                                                    {previewImage && <img src={previewImage} />}
                                                </div>
                                                <div className='preview-frame'>
                                                    <img src={markLogo} className={classes.marklogo} />
                                                </div>
                                            </div>
                                            <div className="button-group">
                                                {/* <button className="btn btn-primary btn-padding btn-rounded me-1">Email</button> */}
                                                <button className="btn btn-primary btn-padding btn-rounded" id="btnstep5" onClick={() => { download(previewImage) }}>Download</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                        case 6:
                            return (<div id="screen6" className="icici-screen icici-screen6" style={{ backgroundImage: `url(${screenBG})` }}>
                                <div className="icici-content">
                                    <div className="icici-header">
                                        <img src={mainLogo} alt="ICICI Prudential" />
                                    </div>
                                    <div className="icici-body">
                                        <div className="icici-inner">
                                            <div className="thankyou">
                                                <h4>Thank You</h4>
                                            </div>

                                            <button className="btn btn-primary btn-padding btn-rounded" id="btnstep6" onClick={() => { onChangeScreen(1) }}>Ok</button>
                                        </div>
                                    </div>
                                    <div className="icici-footer">
                                        <p className="mb-0">* Strictly for internal circulation only. Should noT be further circulated/used for
                                            presentation to a prospect/general public.</p>
                                    </div>
                                </div>
                            </div>)
                        default:
                            return null
                    }
                })()}
            </section>

            <section className="icici-desktop" id="icici-desktop" style={{ backgroundImage: `url(${desktopBG})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="card card-desktop">
                                <div className="card-body">
                                    <img src={mainLogoSVG} alt="ICICI Prudential" />
                                    <h2>Access this page using mobile device only.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CaptureImage;