import React, {forwardRef, RefObject, useEffect, useRef} from 'react';
import interact from 'interactjs'

// @ts-ignore
const DraggableImage = forwardRef(({
                                       src,
                                       id
                                   }: { src: string, id: string }, parentRef: RefObject<null | HTMLDivElement>) => {
    const imgRef = useRef<null | HTMLImageElement>(null);

    useEffect(() => {
        // @ts-ignore
        imgRef.current && (imgRef.current.width = 100)

        // @ts-ignore
        document.addEventListener('touchmove', document.onmousemove)

        var angleScale = {
            angle: 0,
            scale: 1
        }

        var gestureArea = parentRef.current
        var scaleElement = imgRef.current
        var resetTimeout: any;

        if (!gestureArea) return
        if (!scaleElement) return

        function dragMoveListener(event: any) {
            if (!scaleElement) return

            var target = event.target

            var x = (parseFloat(target.getAttribute('data-x')) || 200) + event.dx
            var y = (parseFloat(target.getAttribute('data-y')) || 20) + event.dy


            if (!imgRef.current) return;

            imgRef.current.style.left = x + 'px';
            imgRef.current.style.top = y + 'px';

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
        }

        interact(scaleElement)
            .gesturable({
                listeners: {
                    start (event) {
                        angleScale.angle -= event.angle
                    },
                    move (event) {
                        var currentAngle = event.angle + angleScale.angle
                        var currentScale = event.scale * angleScale.scale
                        if (!scaleElement) return

                        scaleElement.style.transform =
                            'rotate(' + currentAngle + 'deg)' + 'scale(' + currentScale + ')'

                        dragMoveListener(event)
                    },
                    end (event) {
                        angleScale.angle = angleScale.angle + event.angle
                        angleScale.scale = angleScale.scale * event.scale
                    }
                }
            })
            .draggable({
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: 'parent',
                        elementRect: { left: 0, right: 1, top: 0, bottom: 1 },
                    })
                ],
                listeners: {
                    move: dragMoveListener,
                }
            })
    }, [])


    return (
        <img
            id={id}
            src={src}
            className="draggable-image"
            alt="Draggable image"
            ref={imgRef}
            style={{
                position: 'absolute',
                zIndex: 10,
                left: 200,
                top: 20,
                touchAction: 'none'
            }}
        />
    );
})

export default DraggableImage;