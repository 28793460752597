import jQuery from 'jquery';

jQuery(document).ready(function () {

  // Mobile and Desktop Hide - Show 
  if (jQuery(window).width() <= 480) {
    jQuery('#icici-mobile').show();
    jQuery('#icici-desktop').hide();
  }
  else {
    jQuery('#icici-mobile').hide();
    jQuery('#icici-desktop').show();
  }

  jQuery(window).resize(function () {
    if (jQuery(window).width() <= 480) {
      jQuery('#icici-mobile').show();
      jQuery('#icici-desktop').hide();
    }
    else {
      jQuery('#icici-mobile').hide();
      jQuery('#icici-desktop').show();
    }
  });

});
