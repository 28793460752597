import React from 'react';
import { Outlet } from "react-router-dom"
import classes from "./styles.module.css";
// @ts-ignore
import logo from "../../assets/logo.JPG";

const MainWrapper = () => {
    return (
        <div>
            <Outlet />
        </div>
        // <div className={classes.wrapper}>
        //     <header>
        //         <img src={logo} alt="logo" height="100%"/>
        //     </header>

        //     <div>
        //         <Outlet/>
        //     </div>

        //     <footer>
        //         *Strictly for internal circulation only. Should be further circulated/used for presentation to a
        //         prospect/general public.
        //     </footer>
        // </div>
    );
};

export default MainWrapper;