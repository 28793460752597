import React from 'react';
// @ts-ignore
import logo from './assets/logo.JPG'
import Router from "./router";

import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap-icons.css';
import './assets/css/app.css';



function App() {
    return (
        <Router/>
    );
}

export default App;
